import React from "react"
import { Link } from "gatsby"
import './header.scss'

export default () => {

    return (
        <div>  
            <div className="header">
                <div className="container">
                    <h1>Jessa Sparks</h1>
                </div>
            </div>
            <nav className="navbar navbar-expand bg-secondary nav-pills">
                <div className="container">      
                    <div className="navbar-nav">
                        <Link to="/" className="nav-link" activeClassName="active">Home</Link>
                        <Link to="/blog" className="nav-link" activeClassName="active">Blog</Link>
                        <Link to="/projects" className="nav-link" activeClassName="active">Projects</Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}