import React from "react"
import Layout from "../components/layout"
import gimme from "./gimme.png"
import { Helmet } from "react-helmet"

export default () => (
    <Layout>
        <Helmet title="Jessa Sparks | Projects" />
        <div className="row">
            <div className="col-sm">
                <h2>Projects</h2>
            </div>
        </div>

        <div className="card-columns">
            <div className="card">
                <img className="card-img-top" src={gimme} alt="Gimme"/>
                <div className="card-body">
                    <h5 className="card-title mb-2">Take the guesswork out of gift giving
                    without spoiling the surprise</h5>
                    <p></p>
                    <p>Features:</p>
                    <ul>
                        <li>Share your gift ideas with friends and family. Only they will 
                            be able to see what has been purchased!
                        </li>
                        <li>Add an icon to your home screen. Look and feel of a native app 
                            without installing anything
                        </li>
                        <li>Offline support</li>
                    </ul>
                    <a href="https://gimmegimme.gifts" role="button" className="btn btn-primary try-gimme">Try it out!</a>
                </div>
            </div>
        </div>
    </Layout>
)