import React from "react"
import Header from "./header"
import './layout.scss'

export default ({children}) => (   
    <div>       
        <Header/>
        <div className="container main">
            {children}
        </div>
    </div>
)